<template>
    <main class="login-container bg-gray-200">
        <div class="component-container login-form-container h-full">

            <img src="../assets/ttf-logo.png" alt="" class="col-start-5 col-end-9 login-form-logo">

            <form class="col-start-5 col-end-9 bg-white shadow-md rounded px-8 pt-6 pb-8 self-center">
            <div class="mb-4">
                <label
                class="block text-gray-700 text-2xl font-bold mb-2"
                for="username"
                >
                Username
                </label>
                <input
                class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:shadow-outline
                "
                id="username"
                type="text"
                placeholder="Username"
                />
            </div>
            <div class="mb-6">
                <label
                class="block text-gray-700 text-2xl font-bold mb-2"
                for="password"
                >
                Password
                </label>
                <input
                class="
                    shadow
                    appearance-none
                    border border-red-500
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    mb-3
                    leading-tight
                    focus:outline-none focus:shadow-outline
                "
                id="password"
                type="password"
                placeholder="******************"
                />
                <p class="text-red-500 text-xs italic">Please enter a password.</p>
            </div>
            <div class="flex items-center justify-between">
                <button
                class="
 
                    form-login-button
                    text-l
                    text-white
                    font-bold
                    py-2
                    px-4
                    rounded
                    focus:outline-none focus:shadow-outline
                "
                type="button"
                >
                Sign In
                </button>
                <a
                class="
                    inline-block
                    align-baseline
                    font-bold
                    text-sm text-blue-500
                    hover:text-blue-800
                "
                href="#"
                >
                Forgot Password?
                </a>
            </div>
            </form>
        </div>
    </main>
  
</template>

<script>
export default {
  name: "login",
};
</script>

<style scoped>
@import "../styles/resets.css";
@import "../styles/globals.css";

.login-container{
    width: 100vw;
    height: 100vh;
    padding-top: var(--header-height);
}

.login-form-container{
    align-items: unset;
    align-content: center;
}

.form-login-button{
    background-color: #F5BD4D;
}

.form-login-button:hover{
    background-color: #ffb726;
}

@media screen and (max-width: 840px){


    .login-form-container{
        align-items: center;
        justify-content: center;
    }
    .login-form-container form {
        width: 70%;
        margin-right: auto;
        margin-left: auto;

    }

    .login-form-logo{
        max-width: 60%;
        margin: 0 auto;
    }
    }

</style>